import React from 'react';
import axios from 'axios';
import { NSContext, NSLogin, NSToast, authUser, createOrUpdateUser, updateNewsletterUser } from 'aqlrc';
import { Router } from '../routes';
import { withI18next } from '../lib/withI18n';

class Login extends NSLogin {
    handleRegisterSubmit = async (e) => {
        e.preventDefault();
        const { returnUrl } = this.props;
        const {
            subUser, address, subscribeNL
        } = this.state;
        const { lang, routerLang } = this.context.props;
        const {
            password, passwordConfirm, email, confirmEmail,
        } = subUser;
        if (password !== passwordConfirm) {
            NSToast.error('login:error_msg.password_mismatch');
            subUser.password = '';
            subUser.passwordConfirm = '';
            return this.setState({ subUser });
        }
        if (email !== confirmEmail) {
            NSToast.error('login:error_msg.mail_mismatch');
            subUser.confirmEmail = '';
            return this.setState({ subUser });
        }
        /*if (subUser.civility === undefined) {
            return NSToast.warn('login:error_msg.choose_civility');
        }*/
        if (subscribeNL === undefined) {
            return NSToast.warn('login:error_msg.no_news_letter_choice');
        }
        address.firstname = subUser.firstname;
        address.lastname = subUser.lastname;
        address.companyName = subUser.company.name;
        address.civility = subUser.civility;
        subUser.addresses = [address, address];
        subUser.billing_address = 0;
        subUser.delivery_address = 1;
        subUser.preferredLanguage = lang;
        subUser.lang = lang;

        // Inscription
        try {
            await createOrUpdateUser(subUser);
        } catch (err) {
            if (err.response && err.response.data && err.response.data.message) {
                NSToast.error(err.response.data.message);
            } else {
                NSToast.error('common:error_occured');
                console.error(err);
            }
            return;
        }

        if (subscribeNL) {
            // Acceptation de la newsletter
            try {
                await updateNewsletterUser(subUser.email, 'DefaultNewsletter', subscribeNL);
            } catch (err) {
                if (err.response && err.response.data && err.response.data.message) {
                    NSToast.error(err.response.data.message);
                } else {
                    NSToast.error('common:error_occured');
                    console.error(err);
                }
            }
        }

        // Authentification
        try {
            const res = await authUser(subUser.email, subUser.password);

            axios.defaults.headers.common.Authorization = res.data.data;
            NSToast.success('login:valid_msg.register');
            window.localStorage.removeItem('globalMinMaxFilter');
            if (returnUrl) {
                return Router.pushRoute(returnUrl);
            }
            return Router.pushRoute(window.location.pathname.indexOf('/cart') > -1 ? 'cartAddress' : 'account', { lang: routerLang });
        } catch (err) {
            if (err.response && err.response.data && err.response.data.message) {
                NSToast.error(err.response.data.message);
            } else {
                NSToast.error('common:error_occured');
                console.error(err);
            }
        }
    }

    loginRequest = async (email, password) => {
        const { gNext, returnUrl } = this.props;
        const { routerLang } = this.context.props;
        const Router = (gNext && gNext.Router) || undefined;

        // Authentification
        try {
            const res = await authUser(email, password);

            // axios.defaults.headers.common.Authorization = res.data.data;
            window.localStorage.removeItem('globalMinMaxFilter');
            if (returnUrl) {
                return Router.pushRoute(returnUrl);
            }
            let route = 'account';
            if (window.location.pathname.indexOf('cart') > -1) {
                route = 'cartAddress';
            }
            Router.pushRoute(route, { lang: routerLang });
        } catch (err) {
            if (err.response && err.response.data && err.response.data.message) {
                NSToast.error(err.response.data.message);
            } else {
                NSToast.error('common:error_occured');
                console.error(err);
            }
        }
    };

    render() {
        const { t }     = this.props;
        const {
            emailResetPassword, step, subUser, subscribeNL
        } = this.state;
        const { props } = this.context;
        if (!props) { return null; }
        return (
            <div className="section section--connect form">
                <form onSubmit={step === 0 ? this.handleLoginSubmit : (step === 1 ? this.handleRegisterSubmit : this.handleResetSubmit)}>
                    <div className="section__inner">
                        <div className="shell">
                            <div className="article article--center">
                                <h1>{t('login:pageTitle')}</h1>
                            </div>{/* <!-- /.article --> */}

                            <div className="connect">
                                {
                                    step !== 2 && (
                                        <div className="connect__cols">
                                            <div className="connect__col text-right">
                                                <a href="#" className={`link color-${step === 0 ? 'blue' : 'gray'}`} onClick={() => this.setState({ step: 0 })}>{t('login:login')}</a>
                                            </div>{/* <!-- /.connect__col --> */}

                                            <div className="connect__col">
                                                <a href="#" className={`link color-${step === 1 ? 'blue' : 'gray'}`} onClick={() => this.setState({ step: 1 })}>{t('login:register')}</a>
                                            </div>{/* <!-- /.connect__col --> */}
                                        </div>/* <!-- /.connect__cols --> */
                                    )
                                }
                                {
                                    /* Connexion */
                                    step === 0 && (
                                        <>
                                            <div className="connect__fields">
                                                <div className="form__controls connect__field">
                                                    <input type="text" id="email_login" className="field" placeholder={t('login:placeholder.email')} />
                                                </div>{/* <!-- /.form__controls --> */}

                                                <div className="form__controls connect__field">
                                                    <input type="password" id="password_login" className="field" placeholder={t('login:placeholder.password')} />
                                                </div>{/* <!-- /.form__controls --> */}

                                                <a href="#" onClick={() => this.setState({ step: 2 })}>{t('login:forgotPassword')}</a>
                                            </div>{/* <!-- /.connect__fields --> */}

                                            <div className="connect__actions">
                                                <button type="submit" className="btn btn--color color-orange">
                                                    <span className="btn__content">{t('login:btn.logIn')}</span>
                                                </button>
                                            </div>{/* <!-- /.connect__actions --> */}
                                        </>
                                    )
                                }
                                {
                                    /* Inscription */
                                    step === 1 && (
                                        <>
                                            <div className="connect__fields">
                                                <div className="form__controls connect__field">
                                                    <input required type="email" className="field" name="email" id="field-email3" value={subUser.email} placeholder={t('login:placeholder.email')} onChange={(e) => this.handleChangeSub(e)} />
                                                </div>

                                                <div className="form__controls connect__field">
                                                    <input required type="email" className="field" name="confirmEmail" id="field-email-confirm" value={subUser.confirmEmail} placeholder={t('login:placeholder.emailConfirm')} onChange={(e) => this.handleChangeSub(e)} />
                                                </div>

                                                <div className="form__controls connect__field">
                                                    <input required onChange={(e) => this.handleChangeSub(e)} type="password" className="field" name="password" id="field-password3" value={subUser.password} placeholder={t('login:placeholder.password')} />
                                                </div>

                                                <div className="form__controls connect__field">
                                                    <input required onChange={(e) => this.handleChangeSub(e)} type="password" className="field" name="passwordConfirm" id="field-password-confirm3" value={subUser.passwordConfirm} placeholder={t('login:placeholder.passwordConfirm')} />
                                                </div>
                                            </div>{/* <!-- /.connect__fields --> */}

                                            <div className="checkbox-alt">
                                                <input type="checkbox" className="field" name="field-newsletter" id="field-newsletter" checked={subscribeNL} value={subscribeNL} onChange={() => this.setState({ subscribeNL: !subscribeNL })} />

                                                <label htmlFor="field-newsletter">{t('login:newsletter')}</label>
                                            </div>{/* <!-- /.checkbox-alt --> */}

                                            <div className="connect__actions">
                                                <button type="submit" className="btn btn--color color-orange">
                                                    <span className="btn__content">{t('login:btn.register')}</span>
                                                </button>
                                            </div>{/* <!-- /.connect__actions --> */}

                                            <div className="required_fields">
                                                {t('login:requiredFields')}
                                            </div>
                                        </>
                                    )
                                }
                                {
                                    /* Mot de passe oublié */
                                    step === 2 && (
                                        <>
                                            <p>{t('login:forgotPassword')} ?<br />{t('login:txtForgotPassword')} :</p>
                                            <div className="connect__fields">
                                                <div className="form__controls connect__field">
                                                    <input type="text" id="email_login_forgot" className="field" value={emailResetPassword} placeholder={t('login:placeholder.email')} onChange={(e) => this.handleResetPassword(e)} required />
                                                </div>{/* <!-- /.form__controls --> */}
                                            </div>{/* <!-- /.connect__fields --> */}

                                            <div className="connect__actions">
                                                <button type="button" className="btn btn--color color-gray" onClick={() => this.setState({ step: 0 })}>
                                                    <span className="btn__content">{t('login:btn.return')}</span>
                                                </button>
                                                <button type="submit" className="btn btn--color color-orange">
                                                    <span className="btn__content">{t('login:btn.confirm')}</span>
                                                </button>
                                            </div>{/* <!-- /.connect__actions --> */}
                                        </>
                                    )
                                }

                                <div style={{ marginTop: '30px' }} dangerouslySetInnerHTML={{ __html: props.cmsLegalTxt.content || '' }} />
                            </div>{/* <!-- /.connect --> */}
                        </div>{/* <!-- /.shell --> */}
                    </div>{/* <!-- /.section__inner --> */}
                </form>
            </div>/* <!-- /.section --> */
        );
    }
}

export default withI18next([])(Login);
