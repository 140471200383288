import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import { NSContext, getLangPrefix } from 'aqlrc';
import axios from 'axios';
import Layout from '../components/Layout';
import Login from '../components/Login';
import getAPIUrl from '../lib/getAPIUrl';
import { withI18next } from '../lib/withI18n';
import { Router } from '../routes';


class Auth extends React.Component {
    static getInitialProps = async function (ctx) {
        const { lang } = ctx.nsGlobals;
        const returnUrl = ctx.query.return;
        const cmsLegalTxt = await axios.post(`${getAPIUrl(ctx)}v2/component/ns-cms/legalTxt`, { lang });

        return {
            cmsLegalTxt : cmsLegalTxt.data,
            returnUrl
        };
    };

    constructor(props) {
        super(props);
        this.state = {
            ...props
        };
    }

    componentDidMount = () => {
        const { routerLang, user } = this.state;
        window.localStorage.removeItem('filters');
        if (user) {
            Router.pushRoute('account', { lang: routerLang });
        }
    }

    onLangChange = async (lang) => {
        window.location.pathname = `${await getLangPrefix(lang)}/login`;
    }

    render() {
        const {
            oCmsHeader, oCmsFooter, sitename, t, returnUrl
        } = this.props;
        return (
            <NSContext.Provider value={{ props: this.props, state: this.state, onLangChange: (l) => this.onLangChange(l) }}>
                <Layout header={oCmsHeader.content} footer={oCmsFooter.content}>
                    <Head>
                        <title>{sitename} | {t('login:pageTitle')}</title>
                        <meta property="og:type" content="website" />
                    </Head>
                    <div className="intro-alt">
                        <div className="decoration-arrow color-white">
                            <div className="decoration__img bg-purple" />{/* <!-- /.decoration__img --> */}
                        </div>{/* <!-- /.decoration-arrow --> */}
                    </div>{/* <!-- /.intro-alt --> */}
                    <Login gNext={{ Head, Router }} t={t} returnUrl={returnUrl} />
                </Layout>
            </NSContext.Provider>
        );
    }

    getChildContext() {
        return {
            getPageState : () => this.state,
            onLangChange : (lang) => this.onLangChange(lang)
        };
    }

    static childContextTypes = {
        getPageState : PropTypes.func,
        onLangChange : PropTypes.func
    }
}

export default withI18next(['login'])(Auth);
